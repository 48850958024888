import React from "react";
import {
  Text,
  Skeleton,
  HStack,
  Link,
  Avatar,
  Spacer,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";

import type { IAthlete_With_BirthdayFragment } from "lib/api/sdk";
import { formatDate } from "lib/utils/dates/dateFormater";

interface IItem {
  athlete?: IAthlete_With_BirthdayFragment;
  isLoading?: boolean;
}

const Item = ({ athlete, isLoading }: IItem) => {

  const { name, birthday, avatar, user } = athlete || {};

  return (
    <HStack
      w="full"
      spacing={5}
      justify={"space-between"}
      align={"center"}
    >
      <Skeleton isLoaded={!isLoading} minH={15} flex={1}>
        <HStack flex={1}>
          <Text fontWeight={"bold"} textTransform={"capitalize"}>
            {!!birthday && formatDate(birthday, "d MMM")}
          </Text>
          <Spacer />
          <Avatar size="xs" src={avatar} />
          <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
            {name}
          </Text>
        </HStack>
      </Skeleton>
      <Link
        isExternal
        // eslint-disable-next-line i18next/no-literal-string
        href={`mailto:${user?.email}`}
        display={isLoading ? "none" : "block"}
      >
        <EmailIcon />
      </Link>
    </HStack>
  );
};

export default Item;
