import React from "react";
import {
  Text,
  Skeleton,
  HStack,
  Avatar,
  Spacer,
  Button,
  VStack,
  Tag,
} from "@chakra-ui/react";
import { CalendarIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import Link from "next/link";

import SportIconsFactory from "components/Icons/SportIconsFactory";
import type {
  IAthlete_With_WorkoutsFragment,
  IAthlete_WorkoutFragment,
} from "lib/api/sdk";
import { formatDate } from "lib/utils/dates/dateFormater";
import { getPaths } from "lib/utils/paths";
import { useTranslations } from "next-intl";

interface IAthlete extends IAthlete_With_WorkoutsFragment {
  workouts?: IAthlete_WorkoutFragment[];
}

interface IItem {
  athlete?: IAthlete;
  isLoading?: boolean;
}

const GetWorkoutsWithResults = ({ athlete }: IItem) => {
  return athlete?.workouts?.filter(workout => {
    const hasResults =
      workout.comments ||
      workout.motivation ||
      workout.effort ||
      workout.athlete_workout_sections.filter(
        section =>
          section.measured_average_heart_rate ||
          section.measured_duration ||
          section.measured_max_heart_rate ||
          section.measured_speed
      );
    return hasResults;
  });
};

const Item = ({ athlete, isLoading }: IItem) => {
  const t = useTranslations("pages.homePage.newUploads");
  const workoutsWithResults = GetWorkoutsWithResults({ athlete }) || [];

  // Highlight the last submitted workout, in case there are 2 or more
  const lastWorkoutId = workoutsWithResults[workoutsWithResults.length - 1]?.id;

  return (
    <HStack w="full" spacing={5} justify={"space-between"} align={"baseline"}>
      <HStack flex={1} align={"flex-end"}>
        <VStack align={"flex-start"}>
          <HStack flex={1} spacing={3} align={"flex-start"}>
            <Avatar size="md" src={athlete?.avatar} />
            <VStack align={"flex-start"} spacing={1}>
              <Skeleton isLoaded={!isLoading} minH={15}>
                <Text
                  fontSize={"lg"}
                  fontWeight={"bold"}
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {athlete?.name}
                </Text>
              </Skeleton>
              <VStack align={"stretch"}>
                {workoutsWithResults?.slice(0, 2).map(workout => (
                  <Skeleton isLoaded={!isLoading} minH={15} key={workout.id}>
                    <HStack spacing={3}>
                      <HStack spacing={2}>
                        <CalendarIcon fontSize={"sm"} />
                        <Text fontSize={"sm"}>
                          {formatDate(workout.date, "d MMM")}
                        </Text>
                      </HStack>
                      <Tag size="sm">
                        <HStack spacing={1}>
                          <Text fontSize={"sm"} fontWeight={"bold"}>
                            {
                              workout.athlete_workout_sections.find(
                                section => section.type === "main" || section.type === "series_main"
                              )?.workout_zone?.name
                            }
                          </Text>
                          <SportIconsFactory id={workout.workout_type.avatar} />
                        </HStack>
                      </Tag>
                      <HStack spacing={1}>
                        <Text fontSize={"sm"} fontStyle={"italic"}>
                          {workout.comments}
                        </Text>
                      </HStack>
                    </HStack>
                  </Skeleton>
                ))}
                {(workoutsWithResults?.length > 2 && !isLoading) && (
                  <Text fontSize={"sm"} color="gray.300">
                    {t("xMoreUploads", {
                      count: workoutsWithResults?.length - 2,
                    })}
                  </Text>
                )}
              </VStack>
            </VStack>
          </HStack>
        </VStack>
        <Spacer />
        <Link
          passHref
          // eslint-disable-next-line i18next/no-literal-string
          href={`${getPaths.atletas.id(athlete?.id)}?tab=workouts${lastWorkoutId ? `&h=${lastWorkoutId}` : ""}`}
        >
          <Button
            rightIcon={<ExternalLinkIcon />}
            size="sm"
            colorScheme="brandBlue"
            isDisabled={isLoading}
          >
            {t("viewUploads")}
          </Button>
        </Link>
      </HStack>
    </HStack>
  );
};

export default Item;
