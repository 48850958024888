import React from "react";
import {
  Text,
  Skeleton,
  HStack,
  Avatar,
  Spacer,
  Button,
  VStack,
} from "@chakra-ui/react";
import { CalendarIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { isAfter } from "date-fns";
import Link from "next/link";

import type { IAthlete_With_WeeksFragment } from "lib/api/sdk";
import { formatDate } from "lib/utils/dates/dateFormater";
import { getPaths } from "lib/utils/paths";
import { useTranslations } from "next-intl";

interface IItem {
  athlete?: IAthlete_With_WeeksFragment;
  isLoading?: boolean;
}

const Item = ({ athlete, isLoading }: IItem) => {
  const t = useTranslations("pages.homePage.runningOutOfWorkouts");
  const lastAssignedWeek = athlete?.athlete_blocks[0]?.athlete_weeks[0];
  const noWorkouts = isAfter(new Date(), new Date(lastAssignedWeek?.end_date));

  return (
    <HStack w="full" spacing={5} justify={"space-between"} align={"center"}>
      <HStack flex={1}>
        <VStack align={"flex-start"}>
          <HStack flex={1} spacing={3}>
            <Avatar size="md" src={athlete?.avatar} />
            <VStack align={"flex-start"} spacing={1}>
              <Skeleton isLoaded={!isLoading} minH={15}>
                <Text
                  fontSize={"lg"}
                  fontWeight={"bold"}
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {athlete?.name}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={!isLoading} minH={15}>
                {!!lastAssignedWeek &&
                  <HStack spacing={1}>
                    <CalendarIcon
                      mr={1}
                      fontSize={"sm"}
                      color={noWorkouts ? "red.500" : "gray.400"}
                    />
                    <Text
                      fontSize={"sm"}
                      color={noWorkouts ? "red.500" : "gray.400"}
                    >
                      {noWorkouts ? t("lastWorkout") : t("workoutPrescribedUntil")}{" "}
                      <strong>
                        {formatDate(lastAssignedWeek.end_date, "d MMM")}
                      </strong>
                    </Text>
                  </HStack>
                }
              </Skeleton>
            </VStack>
          </HStack>
        </VStack>
        <Spacer />
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Link passHref href={`${getPaths.atletas.id(athlete?.id)}?tab=workouts`}>
          <Button
            rightIcon={<ExternalLinkIcon />}
            size="sm"
            colorScheme="brandBlue"
            isDisabled={isLoading}
          >
            {t("prescribeWorkout")}
          </Button>
        </Link>
      </HStack>
    </HStack >
  );
};

export default Item;
